<template>
    <div class="">
        <SidebarMenu :titulo="tituloMenu"/>
        <div class="vld-parent">
            <loading :active.syn="isLoading" :can-cancel="true"  :is-full-page="fullPage"></loading>
        </div>
        <div class="table-wrapper m-3 p-3">
            <div style="display: flex;">
                <BFormRadioGroup
                    v-model="buttonsSelected"
                    :options="buttonsOptions"
                    button-variant="outline-danger"
                    name="radios-btn-default"
                    buttons
                />
                <BFormInput v-if="buttonsSelected=='mes'" style="width: 180px;" :type="'month'" v-model="fecha" @change="getData"/>
                <BFormInput v-if="buttonsSelected=='dia'" style="width: 180px;" :type="'date'" v-model="fecha" @change="getData"/>
                <BFormSelect v-if="fecha" style="width: 250px;" v-model="sucursal" :options="sucursales" @change="filterSucursal"/>
                <BButton variant="danger" v-if="dataExcel != []" class="mb-2 col-1" @click=descargar >EXCEL TR</BButton>
                <BButton variant="danger" v-if="dataExcel != []" class="mb-2 col-1" @click=descargar2 >EXCEL CON</BButton>
            </div>
            <div>
                <b-tabs 
                    active-nav-item-class="font-weight-bold text-uppercase text-danger"
                    active-tab-class="font-weight-bold text-success"
                    content-class="mt-3"
                    
                >
                    <b-tab title="Sucursales" active>
                        <h4 v-if="consolidado==false">Seleccione una Fecha</h4>
                        <table v-if="consolidado.length >0">
                            <thead>
                                <tr>
                                    <th>Sucursal</th>
                                    <th>Cuenta</th>
                                    <th>Valor</th>
                                    <th>Pendiente</th>
                                    <th>Saldado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in consolidado" :key="row.id">
                                    <td>{{ row.codigo_incocredito }}</td>
                                    <td>{{ row.cuenta }}</td>
                                    <td>{{ row.valor }}</td>
                                    <td>{{ row.pendiente }}</td>
                                    <td>{{ row.saldado }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-tab>
                    <b-tab title="Todo">
                        <h4 v-if="sucursal==false">Seleccione una Sucursal</h4>
                        <div style="display:flex;">
                            <div v-if="sucursal" style="height: 400px;" class="tarjeta m-2">
                                <h1>{{ resumen.titulo }}</h1>
                                <div>Valor: {{ resumen.valor }}</div>
                                <div>Consignados: {{ resumen.consignacion }}</div>
                                <div>Pendientes: {{ resumen.pendiente }}</div>
                                <div>Restante: {{ resumen.restante }}</div>
                            </div>
                            <div v-if="sucursal" style="width: 600px;" class="tarjeta m-2">
                                <table v-if="resumen.consignaciones.length>0">
                                    <thead>
                                        <tr>
                                        <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                                        <th>Banco</th> 
                                        <th>Valor</th> 
                                        <th>Responsable</th> 
                                        <th>Estado</th> 
                                        <th>Imagen</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(detalle, index) in resumen.consignaciones" :key="index">
                                        <td>{{ detalle.banco}}</td>
                                        <td>{{ detalle.valor }}</td>
                                        <td>{{ detalle.responsable }}</td>
                                        <td>{{ detalle.estado }}</td>
                                        <td>
                                            <button v-if="detalle.estado != 'Conciliado'" @click="mostrarInformacion(detalle)">
                                            <IBiEye />
                                            </button>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Pendiente">
                        <h4 v-if="sucursal==false">Seleccione una Sucursal</h4>
                        <div style="display:flex;">

                            <div v-if="sucursal" style="width: 800px;" class="tarjeta m-2">
                                <table v-if="resumen.consignaciones.length>0">
                                    <thead>
                                        <tr>
                                        <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                                        <th>Banco</th> 
                                        <th>Valor</th> 
                                        <th>Responsable</th> 
                                        <th>Estado</th> 
                                        <th>Imagen</th> 
                                        <th>Seleccionar</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr 
                                            v-for="(detalle, index) in consignacionesPendientes" 
                                            :key="index" 
                                        >
                                        <td>{{ detalle.banco}}</td>
                                        <td>{{ detalle.valor }}</td>
                                        <td>{{ detalle.responsable }}</td>
                                        <td>{{ detalle.estado }}</td>
                                        <td>
                                            <button @click="mostrarInformacion(detalle)">
                                            <IBiEye />
                                            </button>
                                        </td>
                                        <td>
                                            <input 
                                                type="checkbox" 
                                                :value="detalle" 
                                                @change="toggleSeleccion(detalle)" 
                                            />
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="sucursal">
                                <div v-if="seleccionados.length>0" style="width: 450px;" class="tarjeta m-2">
                                    <table v-if="seleccionados.length>0">
                                        <thead>
                                            <tr>
                                            <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                                            <th>Banco</th> 
                                            <th>Valor</th> 
                                            <th>Responsable</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr 
                                                v-for="detalle in seleccionados" 
                                                :key="index" 
                                            >
                                            <td>{{ detalle.banco}}</td>
                                            <td>{{ detalle.valor }}</td>
                                            <td>{{ detalle.responsable }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h3>Total: {{totalSeleccionados}}</h3>
                                    <BButton variant="danger" class="mb-2" @click="showConsignacionModal = true" >SALDAR</BButton>
                                </div>
                            </div>

                        </div>
                    </b-tab>
                    <b-tab title="Saldado">
                        <h4 v-if="sucursal==false">Seleccione una Sucursal</h4>
                        <div style="display:flex;">
                            <div v-if="sucursal" style="width: 600px;" class="tarjeta m-2">
                                <table v-if="resumen.consignaciones.length>0">
                                    <thead>
                                        <tr>
                                        <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                                        <th>Banco</th> 
                                        <th>Valor</th> 
                                        <th>Responsable</th> 
                                        <th>Estado</th> 
                                        <th>Imagen</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr 
                                            v-for="(detalle, index) in consignacionesSaldadas" 
                                            :key="index" 
                                        >
                                        <td>{{ detalle.banco}}</td>
                                        <td>{{ detalle.valor }}</td>
                                        <td>{{ detalle.responsable }}</td>
                                        <td>{{ detalle.estado }}</td>
                                        <td>
                                            <button @click="mostrarInformacion(detalle)">
                                            <IBiEye />
                                            </button>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
            
            
        </div>
        <div v-if="showImagenModal" class="modal">
          <div class="modal-content small-modal">
            <span class="close" @click="showImagenModal = false">&times;</span>
            <vue-image-zoomer :regular="`data:${imageContent};base64,${imageBlob}`" :zoom="`data:${imageContent};base64,${imageBlob}`" class="zoom-image">
              <template #placeholder>
                <img :src="`data:${imageContent};base64,${imageBlob}`" class="custom-image" />
              </template>
            </vue-image-zoomer>
          </div>
        </div>


        <div v-if="showConsignacionModal" class="modal d-flex justify-content-center align-items-center">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Saldar</h2>
                    <button type="button" class="close" @click="showConsignacionModal = false">
                    &times;
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="settleInvoice">
                        <div class="form-group">
                            <label for="valor">Valor a Consignar:</label>
                            <input class="form-control" v-model="totalSeleccionados" readonly>
                        </div>
                        <div class="form-group">
                            <label for="fechaConsignacion">Fecha de Consignación:</label>
                            <input type="date" class="form-control" v-model="saldar.fechaConsignacion" required>
                        </div>
                        <div class="form-group">
                            <label for="detalle">Detalle:</label>
                            <textarea class="form-control" v-model="saldar.detalle" required></textarea>
                        </div>
                        <div class="text-center mt-4">
                            <BButton type="submit" variant="danger">Guardar</BButton>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarMenu from '@/components/Intranet/SidebarMenu/SidebarMenu.vue';
import Loading from 'vue-loading-overlay';
import backendRouter from '@/components/BackendRouter/BackendRouter';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { VueImageZoomer } from 'vue-image-zoomer';
import 'vue-image-zoomer/dist/style.css';

export default {
  data() {
    return {
      tituloMenu: 'Informe Corresponsal',
      fecha: false,
      consolidado: false,
      showImagenModal: false,
      isLoading: false,
      image: null,
      imageBlob: null,
      imageContent: null,
      showConsignacionModal: false,
      sucursales: [{'value': '', 'text': ''}],
      sucursal: false,
      seleccionados: [],
      saldar: {},
      totalSeleccionados: 0,
      totalNumero: 0,
      resumen: {},
      buttonsOptions: [
        {text: 'Mes', value: 'mes'},
        {text: 'Día', value: 'dia'},
      ],
      buttonsSelected: 'mes',
      dataExcel: [],
      fullPage: true,
    };
  },
  methods: {
    settleInvoice() {
      console.log('settle invoice');
      console.log(this.sucursal);
      const path = backendRouter.data + 'settle-invoice';
      const data = {
        'saldar': this.saldar,
        'seleccionados': this.seleccionados,
        'total': this.totalNumero,
        'fecha': this.fecha,
        'jwt': this.$cookies.get('jwt'),
        'sucursal': this.sucursal,
      };
      axios.post(path, data).then((response) => {
        this.$swal('Guardado con éxito');
      });
    },
    getData() {
      this.isLoading = true;
      if (this.fecha != '') {
        const path = backendRouter.data + 'select-datos-corresponsal';
        const data = {
          'fecha': this.fecha,
        };
        axios.post(path, data).then((response) => {
          this.consolidado = response.data.consolidado;
          this.sucursales = response.data.sucursales;
          this.sortSucursales();
          this.dataExcel = response.data.data;
          this.isLoading = false;
          this.resumen = {};
          this.filterSucursal();
        }).catch((error) => {
          this.consolidado = false;
          this.isLoading = false;
        });
      } else {
        this.consolidado = false;
        this.isLoading = false;
      }
    },
    mostrarInformacion(detalle) {
      const path = backendRouter.data + 'get-imagen-corresponsal';
      const data = detalle;
      this.isLoading = true;
      axios.post(path, detalle).then((response) => {
        this.imageBlob = response.data.image;
        this.imageContent = response.data.content_type;
        this.showImagenModal = true;
        this.isLoading = false;
      });
    },
    descargar() {
      const data = this.dataExcel;
      const fileName = 'download.xlsx';
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, fileName);
    },
    descargar2() {
      const data = this.resumen.consignaciones;
      const fileName = 'download.xlsx';
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, fileName);
    },
    formatNumber(number) {
      return number.toLocaleString();
    },
    formatoMoneda(valor) {
      const resultado = parseFloat(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
      });
      return resultado;
    },
    toggleSeleccion(detalle) {
      console.log(detalle);
      const index = this.seleccionados.findIndex(item => item === detalle);
      if (index > -1) {
        this.seleccionados.splice(index, 1);
      } else {
        this.seleccionados.push(detalle);
      }
      const numeros = this.seleccionados.map(valor =>
        parseFloat(valor.valor.replace(/[$,]/g, ""))
      );
      this.totalNumero = numeros.reduce((acumulador, numero) => acumulador + numero, 0);
      this.totalSeleccionados = this.formatoMoneda(this.totalNumero);
    },
    filterSucursal() {
      this.isLoading = true;
      if (this.sucursal) {
        const path = backendRouter.data + 'resumen-corresponsal';
        const data = {
          'fecha': this.fecha,
          'sucursal': this.sucursal,
        };
        axios.post(path, data).then((response) => {
          this.resumen = response.data;
          this.isLoading = false;
        }).catch((error) => {
          this.sucursal = false;
          this.isLoading = false;
        });
      } else {
        this.sucursal = false;
        this.isLoading = false;
      }
    },
    sortSucursales() {
      this.sucursales.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });
    },
  },
  components: {
    SidebarMenu,
    Loading,
    VueImageZoomer,
  },
  created() {
    // this.getData();
  },
  computed: {
    consignacionesPendientes() {
      console.log(this.resumen.consignaciones);
      return this.resumen?.consignaciones?.filter(detalle => detalle.estado === 'pendiente') || [];
    },
    consignacionesSaldadas() {
      return this.resumen?.consignaciones?.filter(detalle => detalle.estado === 'saldado') || [];
    },
  },
};
</script>

<style scoped>
    .table-wrapper {
        width: 90%;
        overflow-x: auto;
        
    }
    .tarjeta {
      padding: 30px;
      width: 350px;
      border-radius: 15px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid rgba(0, 0, 0, 0.1);
      align-items: center;
      box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
      overflow: auto
    }
    .modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


table {
    height: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    z-index: 1;
} */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-height: 80%; 
  overflow: auto; 
}
.small-modal {
  max-width: 80%; 
  max-height: 80%; 
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.zoom-image {
  width: 100%;
  transition: transform 0.3s ease;
}

.custom-image {
  width: 100%;
  max-width: 50%;
  height: auto; 
}
</style>