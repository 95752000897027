<template>
    <div>
        <SidebarMenu :titulo="tituloMenu" />
        <div class="p-5">
            <div class="row">
                <div class="col-8">
                    <select class="card" id="precios" v-model="precio" @change="select_price">
                        <option v-for="precio in lista_precios" :key="precio.id" :value="precio.id">{{ precio.nombre }}</option>
                    </select>
                </div>
                <BButton variant="danger" v-if="excel" class="mb-2 col-1" @click="descargar">EXCEL</BButton>
            </div>
            <div class="col-md-12">
                <input v-model="filtro" placeholder="Filtrar" />
                <table v-if="items.length > 0" class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th v-for="field in fields" :key="field.key">{{ field.label }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in itemsFiltrados" :key="index">
                            <td style="text-align: center;" v-for="field in fields" :key="field.key">
                                <template v-if="field.key === 'variation'">
                                    <template v-if="item[field.key] && item[field.key].startsWith('down')">
                                        <div style="display: flex; align-items: center; justify-content: center; gap: 8px;">
                                            <!-- Valor en pesos -->
                                            <span>
                                            {{
                                                new Intl.NumberFormat('es-CO', {
                                                style: 'currency',
                                                currency: 'COP',
                                                minimumFractionDigits: 0
                                                }).format(Number(item[field.key].split('-')[1]))
                                            }}
                                            </span>

                                            <!-- Círculo con porcentaje -->
                                            <span
                                            style="
                                                background-color: rgba(255, 0, 0, 0.15);
                                                color: red;
                                                border: 1px solid rgba(255, 0, 0, 0.3);
                                                border-radius: 50%;
                                                min-width: 40px;
                                                min-height: 40px;
                                                padding: 4px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                font-size: 0.8em;
                                                font-weight: bold;
                                                position: relative;
                                                top: -4px;
                                                box-sizing: border-box;
                                                text-align: center;
                                            "
                                            >
                                            {{ item[field.key].split('-')[2] }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-if="item[field.key] && item[field.key].startsWith('up')">
                                        <div style="display: flex; align-items: center; justify-content: center; gap: 8px;">
                                            <!-- Valor en pesos -->
                                            <span>
                                            {{
                                                new Intl.NumberFormat('es-CO', {
                                                style: 'currency',
                                                currency: 'COP',
                                                minimumFractionDigits: 0
                                                }).format(Number(item[field.key].split('-')[1]))
                                            }}
                                            </span>

                                            <!-- Círculo con porcentaje -->
                                            <span
                                            style="
                                                background-color: rgba(0, 128, 0, 0.15);
                                                color: green;
                                                border: 1px solid rgba(0, 128, 0, 0.3);
                                                border-radius: 50%;
                                                min-width: 40px;
                                                min-height: 40px;
                                                padding: 4px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                font-size: 0.8em;
                                                font-weight: bold;
                                                position: relative;
                                                top: -4px;
                                                box-sizing: border-box;
                                                text-align: center;
                                            "
                                            >
                                            {{ item[field.key].split('-')[2] }}
                                            </span>
                                        </div>
                                    </template>
                                    <span v-if="item[field.key] === 'neutral'" style="color: orange; font-size: 1.5em;">-</span>
                                </template>
                                <template v-else-if="field.key === 'botones'">
                                    <button @click="historico(item)">
                                        <IBiEye />
                                    </button>
                                </template>
                                <template v-else>
                                    {{ item[field.key] }}
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import router from '@/router'
import backendRouter from '@/components/BackendRouter/BackendRouter'
import SidebarMenu from '@/components/Intranet/SidebarMenu/SidebarMenu.vue'
import Loading from 'vue-loading-overlay'
import * as XLSX from 'xlsx'

export default {
    data() {
        return {
            tituloMenu: 'Precios',
            excel: false,
            filtro: '',
            data: [],
            items: [],
            precio: '',
            lista_precios: [],
            fields: [],
            isLoading: false,
            fullPage: true,
        }
    },
    methods: {
        descargar() {
            const data = this.items
            const fileName = 'download.xlsx'
            const workbook = XLSX.utils.book_new()
            const worksheet = XLSX.utils.json_to_sheet(data)
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
            XLSX.writeFile(workbook, fileName)
        },
        historico(item) {
            this.items = []
            const path = backendRouter.data + 'lista-productos-prepago-equipo'
            const data = {
                precio: this.precio,
                equipo: item.equipo,
            }
            axios
                .post(path, data)
                .then((response) => {
                    console.log("Datos recibidos del backend en 'historico':", response.data.data) // Depuración
                    this.items = response.data.data
                    this.generateTableFields(false)
                })
                .catch((error) => {
                    this.$swal(error.response.data.detail)
                })
        },
        get_data() {
            const path = backendRouter.data + 'lista-productos-prepago'
            axios
                .put(path, { jwt: this.$cookies.get('jwt') })
                .then((response) => {
                    this.lista_precios = response.data.data
                })
                .catch((error) => {
                    router.push('/login')
                    this.$swal(error.response.data.detail)
                })
        },
        select_price() {
            console.log("Precio seleccionado:", this.precio) // Depuración
            this.items = []
            const path = backendRouter.data + 'lista-productos-prepago'
            const data = {
                data: this.data,
                precio: this.precio,
            }
            axios
                .post(path, data)
                .then((response) => {
                    console.log("Datos recibidos del backend en 'select_price':", response.data.data) // Depuración
                    this.items = response.data.data
                    console.log("Items después de asignar:", this.items) // Depuración
                    this.generateTableFields(true)
                    this.excel = true
                })
                .catch((error) => {
                    this.$swal(error.response.data.detail)
                })
        },
        generateTableFields(boton) {
            this.fields = Object.keys(this.items[0] || {}).map((key) => {
                return { key: key, label: key }
            })

            // Asegurar que "variation" está en los campos de la tabla
            if (!this.fields.some((f) => f.key === 'variation')) {
                this.fields.push({ key: 'variation', label: 'Indicador' })
            }

            if (boton) {
                this.fields.push({ key: 'botones', label: 'Acciones' })
            }
        },
    },
    components: {
        SidebarMenu,
        Loading,
    },
    created() {
        this.get_data()
    },
    computed: {
        itemsFiltrados() {
            return this.items.filter((item) => {
                return item.equipo.toLowerCase().includes(this.filtro.toLowerCase())
            })
        },
    },
}
</script>

<style scoped>
</style>