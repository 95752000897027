<template>
    <div class="">
        <form>
            <div>
                <SidebarMenu :titulo="tituloMenu"/>
                <div class="vld-parent">
                    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"></loading>
                </div>

                <div v-if="create" class="table-wrapper m-3 p-3">
                    <div style="margin-top: 30px;">
                        <input id="archivoExcel" type="file" @change="subirExcel()"> 
                    </div>
                    <div style="height: 20px;"></div>
                    <div>
                        <BButton variant="danger" v-if="items.length > 0" color="#E11419" dark class="mb-2" @click="accion_boton">{{titulo_boton}}</BButton>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th v-for="j in titulos" :key="j">{{ j }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in items" :key="row">
                                <td v-for="j in row" :key="j">{{ j }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import SidebarMenu from '@/components/Intranet/SidebarMenu/SidebarMenu.vue';
import readXlsFile from 'read-excel-file';
import backendRouter from '@/components/BackendRouter/BackendRouter';
import axios from 'axios';

export default {
    data() {
        return {
            tituloMenu: 'corresponsal',
            create: true,
            isLoading: false,
            fullPage: true,
            accion_boton: '',
            titulo_boton: 'guardar',
            items: [],
            registrosDivididos: [],
        };
    },
    methods: {
        subirExcel() {
            this.titulo_boton = 'GUARDAR';
            this.accion_boton = this.guardar;
            const input = document.getElementById("archivoExcel");
            readXlsFile(input.files[0]).then((rows) => {
                this.titulos = rows[0];
                this.items = rows.slice(1);
                this.dividirRegistros();
            });
        },
        dividirRegistros() {
            const maxRegistros = 2999;
            this.registrosDivididos = [];
            for (let i = 0; i < this.items.length; i += maxRegistros) {
                this.registrosDivididos.push(this.items.slice(i, i + maxRegistros));
            }
        },
        async guardar() {
            this.isLoading = true;
            const path = backendRouter.data + 'guardar-datos-corresponsal';
            console.log('envio datos')
            console.log('Bloques de datos ',this.registrosDivididos.length)
            let validate = true
            for (let i=0; i < this.registrosDivididos.length; i++ ){
                console.log('Procesando registro ', i+1)
                const data = {
                    'cabecera': this.titulos,
                    'items': this.registrosDivididos[i],
                };
                let response = await axios.post(path, data)
                console.log(response.status)
                if (response.status != 200){
                    validate = false
                }
            
            }
            console.log('terminado ciclo resultado: ', validate)
            if (validate){
                this.isLoading = false
            }
        }
    },
    components: {
        SidebarMenu,
        Loading
    }
};
</script>

<style scoped>
.table-wrapper {
    width: 90%;
    overflow-x: auto;
    height: 500px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    z-index: 1;
}

.btn-danger {
    background-color: #E11419;
    border-color: #E11419;
}
</style>